import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  ApiUrl: string = environment.apiBaseUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  accessTokenObj = JSON.parse(localStorage.getItem("currentAdmin"));

  constructor(private http: HttpClient) { }


  validatelogin(formData:any) {
    //let users = { admin_name : admin_name,admin_password : admin_password }
    return this.http.post<any>(`${this.ApiUrl}/login`, formData, { headers: this.headers })
        .pipe(map(user => {
            if (user && user.token) {
                localStorage.setItem('currentAdmin', JSON.stringify(user));
            }
            return user;
        }));
  }

  public get getAuthenticatedUser() {
    let accessTokenObj = JSON.parse(localStorage.getItem("currentAdmin"));
      if(accessTokenObj) {
        return accessTokenObj;
      }
  }


}
