import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx-home-circle' ,
        link: '/'
    },  
    {
        id: 2,
        label: 'Warriors',
        icon: 'bx bx-user-check',
        link: '/warriors'
    },   
    {
        id: 5,
        label: 'Compensation',
        icon: 'bx bx-package',
        subItems: [
            {
                id: 6,
                label: 'Setup Plan',
                link: '/compensation/plan',
                parentId: 5
            }, 
            {
                id: 66,
                label: 'Setup Rank',
                link: '/compensation/rank',
                parentId: 5
            },           
            {
                id: 7,
                label: 'Level Commissions',
                link: '/compensation/level-commission',
                parentId: 5
            },
            // {
            //     id: 77,
            //     label: 'Retention & Downline',
            //     link: '/compensation/retention-downlines',
            //     parentId: 5
            // },
            // {
            //     id: 777,
            //     label: 'Retention Downline Bonus',
            //     link: '/compensation/rentention-downline-bonus',
            //     parentId: 5
            // },
        ]
    },
    {
        id: 8,
        label: 'Staff Management',
        icon: 'bx bx-user',
        subItems: [
            {
                id: 9,
                label: 'Staffs',
                link: '/staff-management/staffs',
                parentId: 8
            },
        ]
    },
    {
        id: 10,
        label: 'Package History',
        icon: 'bx bx-file' ,
        link: '/package-history'
    },  
    {
        id: 11,
        label: 'Delete Commission',
        icon: 'bx bx-trash' ,
        link: '/commissions-data-table'
    },  
    {
        id: 111,
        label: 'Removed Commission',
        icon: 'bx bx-x' ,
        link: '/removed-commissions'
    },  
    {
        id: 12,
        label: 'Network',
        icon: 'bx bx-git-branch',
        subItems: [
            {
                id: 13,
                label: 'Downlines',
                link: '/network/downlines',
                parentId: 12
            },            
        ]
    },
    {
        id: 14,
        label: 'Wallet Management',
        icon: 'bx bx-wallet',
        subItems: [
            {
                id: 77,
                label: 'Warrior Wallet Balance',
                link: '/wallet/warrior-wallet-balance',
                parentId: 14
            },
            {
                id: 15,
                label: 'Send Bonus',
                link: '/wallet/send-bonus',
                parentId: 14
            },                 
            {
                id: 88,
                label: 'Send Payout',
                link: '/wallet/send-payout',
                parentId: 14
            }       
        ]
    },
    {
        id: 91,
        label: 'FAQ',
        link: '/faq',
        icon: 'bx bx-help-circle'
       
    },
    {
        id: 31,
        label: 'Settings',
        icon: 'bx bx-cog',
        subItems: [
            {
                id: 41,
                label: 'Email Templates',
                link: '/settings/email-template',
                parentId: 31
            },
            {
                id: 51,
                label: 'SMS Templates',
                link: '/settings/sms-template',
                parentId: 31
            },
            {
                id: 52,
                label: 'General Settings',
                link: '/settings/general-settings',
                parentId: 31
            }
        ]
    },
    {
        id: 22,
        label: 'Warrior Live',
        link: '/warrior-live',
        icon: 'bx bx-video'        
    },
    {
        id: 23,
        label: 'Warrior School',
        link: '/warrior-school',
        icon: 'bx bxs-city'        
    },
    {
        id: 222,
        label: 'Calendar',
        link: '/calendar',
        icon: 'bx bx-calendar'        
    },
    {
        id: 223,
        label: 'Warrior Feedback',
        link: '/warrior-feedback',
        icon: 'bx bx-pencil'        
    },
    {
        id: 224,
        label: 'Warrior Weekly',
        link: '/warrior-weekly',
        icon: 'bx bx-calendar'        
    },
    
    {
        id: 230,
        label: 'Resources',
        link: '/resources-management/resources',
        icon: 'bx bx-calendar'        
    },
    {
        id: 232,
        label: 'Business Resources',
        link: '/business-resources-management/businessresources',
        icon: 'bx bx-calendar'        
    },
    
    {
        id: 231,
        label: 'Warrior Roadmap',
        link: '/warrior-roadmap',
        icon: 'bx bx-git-branch'        
    },
    {
        id: 225,
        label: 'Report',
        icon: 'bx bxs-report',
        subItems: [
            {
                id: 226,
                label: 'Level Commission',
                link: '/report/level-commission',
                parentId: 225
            },            
            {
                id: 227,
                label: 'Retention',
                link: '/report/retention',
                parentId: 225
            },
            {
                id: 228,
                label: 'Downline',
                link: '/report/downline',
                parentId: 5
            },
        ]
    },
    {
        id: 229,
        label: 'Chat Management',
        icon: 'bx bx-user',
        subItems: [
            {
                id: 230,
                label: 'Manage Warriors',
                link: '/chat-management/manage-warriors',
                parentId: 229
            },
            {
                id: 231,
                label: 'Manage Channels',
                link: '/chat-management/manage-channels',
                parentId: 229
            },
        ]
    },
    {
        id: 230,
        label: 'Warrior Fitness',
        link: '/warrior-fitness',
        icon: 'bx bxs-city'
    }
];

