import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-aff-view-button',
  templateUrl: './aff-view-button.component.html',
  styleUrls: ['./aff-view-button.component.scss']
})
export class AffViewButtonComponent implements OnInit,ICellRendererAngularComp {
  cellvalue: any;
  constructor() { }

  ngOnInit(): void {
  }
  agInit(params:any) {
    this.cellvalue = params.value;
  }
  refresh(params:any):boolean {
    this.cellvalue = params.value;
    return true;
  }
}
