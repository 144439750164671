import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-ag-grid-currency',
  templateUrl: './ag-grid-currency.component.html',
  styleUrls: ['./ag-grid-currency.component.scss']
})
export class AgGridCurrencyComponent implements OnInit {
  cellvalue: any;
  currency: any;  
  constructor() { }

  ngOnInit() {
    this.currency = "$";
  }

  agInit(params:any) {
    this.cellvalue = params.value;
  }
  refresh(params:any):boolean {
    this.cellvalue = params.value;
    return true;
  }

}
